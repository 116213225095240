import React from 'react';
import { NavLink } from 'react-router-dom';
import MainMenu from "./MainMenu";

import '../styles/HomeBanner.scss';
const currentYear = new Date().getFullYear();
const HomeBanner = (props) => {

    return (
        <div className={'notranslate section ' + props.hide} id="section0">
            <div className="logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="65" height="43" viewBox="0 0 65 43" fill="none">
                    <g clip-path="url(#clip0_116_944)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.1253 34.1607H17.5145L10.4682 7.23254H25.0326L25.8737 8.83932H35.8497C43.0812 8.83932 50.5837 14.8982 52.5212 22.3034C54.4587 29.7086 50.1276 35.7675 42.8961 35.7675H39.9664L39.1253 34.1607ZM22.0893 1.60679L21.2482 0H0L10.8307 41.3932H42.9107L43.7518 43H65L54.1683 1.60679H22.0893ZM35.9881 28.1657L36.8292 29.7725H41.3279C45.3277 29.7725 47.7388 26.3992 46.6668 22.3034C45.5947 18.2076 41.4186 14.8343 37.4188 14.8343H29.0119L28.1708 13.2275H17.8917L21.8008 28.1647H35.9881V28.1657Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_116_944">
                            <rect width="65" height="43" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div className="intro">
                <h1 className="head-tag">
                    <span>Drift is</span><span>my therapy</span>
                </h1>
                <span className={'copyright gfdgdf'}>© {currentYear}</span>
            </div>

            <div className={'main-social'}>
                <ul>
                    <li>
                        <NavLink to='https://t.me/alexdracing'  className='telegram' target={'_blank'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6ZM15.9488 16.4887C16.32 14.4975 17.0512 10.1775 17.22 8.4C17.2259 8.31707 17.2225 8.22166 17.2196 8.14006C17.2169 8.06668 17.2147 8.00446 17.22 7.9725C17.2087 7.8825 17.175 7.75875 17.0625 7.66875C16.9275 7.55625 16.7138 7.53375 16.6238 7.53375C16.1963 7.53375 15.555 7.75875 12.4275 9.06375C11.3363 9.51375 9.15375 10.4587 5.86875 11.8875C5.34 12.1012 5.05875 12.3038 5.03625 12.5062C4.9959 12.8593 5.43485 12.9953 6.03688 13.1818C6.10633 13.2033 6.17795 13.2255 6.25125 13.2487C6.825 13.44 7.59 13.6537 7.995 13.665C8.355 13.6762 8.76 13.5188 9.21 13.215C12.2813 11.145 13.8562 10.0988 13.9575 10.0763C13.9663 10.075 13.9753 10.0736 13.9843 10.0722C14.0561 10.0609 14.1338 10.0488 14.1937 10.0988C14.2189 10.1254 14.2372 10.1577 14.247 10.193C14.2568 10.2283 14.2578 10.2655 14.25 10.3012C14.2053 10.4889 12.3796 12.1808 11.5455 12.9538C11.3293 13.1542 11.1797 13.2928 11.145 13.3275C11.0585 13.4155 10.9706 13.499 10.886 13.5794C10.3429 14.0952 9.93393 14.4836 10.8975 15.1163C11.3729 15.4295 11.751 15.6871 12.1327 15.9471C12.5187 16.2101 12.9084 16.4756 13.4062 16.8038C13.5327 16.8873 13.6536 16.9735 13.7715 17.0576C14.2412 17.3924 14.6622 17.6925 15.1838 17.6475C15.4762 17.6138 15.7913 17.3325 15.9488 16.4887Z" fill="white"/>
                            </svg>

                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='https://www.youtube.com/@AlexDProDrift'  className='youtube' target={'_blank'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6ZM14.3304 12.008L10.5054 9.83438V14.1817L14.3304 12.008ZM19.0071 8.4683C19.3125 9.61741 19.3125 12.0121 19.3125 12.0121C19.3125 12.0121 19.3125 14.4067 19.0071 15.5518C18.8384 16.1866 18.3442 16.6647 17.7134 16.8335C16.5763 17.1429 12 17.1429 12 17.1429C12 17.1429 7.42366 17.1429 6.28661 16.8375C5.6558 16.6687 5.16161 16.1906 4.99286 15.5558C4.6875 14.4067 4.6875 12.0121 4.6875 12.0121C4.6875 12.0121 4.6875 9.61339 4.99286 8.4683C5.16161 7.83348 5.6558 7.33527 6.28661 7.16652C7.42366 6.85714 12 6.85714 12 6.85714C12 6.85714 16.5763 6.85714 17.7134 7.16652C18.3442 7.33527 18.8384 7.83348 19.0071 8.4683Z" fill="white"/>
                            </svg>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='https://rtd.rt.com/films/come-drift-with-me/'  className='rt' target={'_blank'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V9.68367H19.3878V14.9999H16.1172L16.117 9.68367H13.3678V9H22V6C22 3.79086 20.2091 2 18 2H6ZM13.1769 9.53146C12.8795 9.20777 12.473 9.01721 12.0433 9L5 9.00007V15H8.29025V12.4694H9.24015L10.9147 15H14.369L12.4888 12.3852C12.8235 12.2726 13.1152 12.052 13.3216 11.7553C13.528 11.4586 13.6385 11.1012 13.637 10.7347C13.6393 10.2866 13.4743 9.85514 13.1769 9.53146ZM10.0974 11.4109C9.92894 11.5973 9.69845 11.7095 9.45317 11.7244L9.4605 11.7244H8.29033V9.72191H9.45318C9.68898 9.73566 9.91147 9.84021 10.0774 10.0153C10.2573 10.2028 10.3585 10.4569 10.359 10.7219C10.3594 10.9779 10.2658 11.2244 10.0974 11.4109Z" fill="white"/>
                            </svg>

                        </NavLink>
                    </li>
                </ul>
            </div>

            <MainMenu />

        </div>
    );
};

export default HomeBanner;